import { Event, EventAction, EventCategory } from '@/utils/goggleAnalytics/gtag';

import type { IEvent } from '@/utils/goggleAnalytics/gtag';

export const SEASONAL_DEMAND_ENABLED = false;
export const SEASONAL_DEMAND_ENABLED_CL = true;

export const trackingEvent: IEvent = {
  action: EventAction.CLICK,
  category: EventCategory.HOME,
  event: Event.B_RESTREAENVIO,
  value: 'Restrea tu envío',
};

export const loginEvent: IEvent = {
  action: EventAction.CLICK,
  category: EventCategory.HOME,
  event: Event.B_INGRESA,
  value: 'Iniciar sesión',
};

export const sellerEvent: IEvent = {
  action: EventAction.CLICK,
  category: EventCategory.HOME,
  event: Event.B_HABLARCONVENTAS,
  value: 'Hablar con ventas',
};

export const registerCallEvent: IEvent = {
  action: EventAction.CLICK,
  category: EventCategory.HOME,
  event: Event.B_AGENDARLLAMADA,
  value: 'Agendar llamada',
};
