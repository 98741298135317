import Link from 'next/link';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';

import { FabButtonAnimate, FabButtonAnimateOutlined } from '@/components/animate';
import Image from '@/components/image/Image';
import { HEADER } from '@/config-global';
import useOffSetTop from '@/hooks/useOffSetTop';
import useResponsive from '@/hooks/useResponsive';
import {
  PATH_DOCS,
  PATH_ENTER,
  PATH_SEARCH,
  PATH_TALK_ADVISOR_MAIN,
  PATH_TICKET_ZOHODESK_CL,
  PATH_TICKET_ZOHODESK_LATAM,
} from '@/routes/paths';
import { useLayoutStore } from '@/stores/layout/layout.store';
import { bgBlur } from '@/utils/cssStyles';
import { mapEvent } from '@/utils/goggleAnalytics/gtag';

import {
  loginEvent,
  SEASONAL_DEMAND_ENABLED,
  SEASONAL_DEMAND_ENABLED_CL,
  sellerEvent,
  trackingEvent,
} from './constants';
import navConfig from './nav/config-navigation';
import NavDesktop from './nav/desktop';
import NavMobile from './nav/mobile';
import { MainBox, SeparatorBox } from './styles';

import type { BoxProps } from '@mui/material';

const Header = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const isSmallDesktop = useResponsive('down', 1280);
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  const country = useLayoutStore((state) => state.country);

  const { t } = useTranslation();

  return (
    <AppBar color="inherit" sx={{ boxShadow: 0 }}>
      <MainBox>
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: HEADER.H_MOBILE + 20,
              lg: HEADER.H_MAIN_DESKTOP,
            },
            transition: theme.transitions.create(['height', 'background-color'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(isOffset && {
              ...bgBlur({ color: theme.palette.background.default }),
              height: {
                md: HEADER.H_MAIN_DESKTOP - 16,
              },
            }),
          }}
        >
          <Container
            sx={{
              height: 1,
              display: 'flex',
              alignItems: 'center',
              flexWrap: { lg: 'nowrap' },
              placeContent: isDesktop ? 'space-evenly' : 'space-between',
              m: isDesktop ? 0 : 2,
            }}
            maxWidth={false}
          >
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Link href={PATH_DOCS.home} data-cy="link-home">
                  <Image src="/assets/images/logos/logo_99minutos.svg" alt="99minutos" />
                </Link>
              </Box>
              <Box>{isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}</Box>
            </Box>

            {isDesktop && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <FabButtonAnimateOutlined
                  key="btn-tracking"
                  data-cy="btn-tracking"
                  variant="outlinedExtended"
                  target="_blank"
                  href={PATH_SEARCH}
                  size={!isSmallDesktop ? 'medium' : 'small'}
                  color="primary"
                  onClick={() => mapEvent(trackingEvent)}
                >
                  {t('LAYOUT.HEADER.ACTIONS.TRACKING')}
                </FabButtonAnimateOutlined>
                <FabButtonAnimateOutlined
                  key="btn-login"
                  data-cy="btn-login"
                  variant="outlinedExtended"
                  target="_blank"
                  href={PATH_ENTER}
                  size={!isSmallDesktop ? 'medium' : 'small'}
                  color="primary"
                  onClick={() => mapEvent(loginEvent)}
                >
                  {t('LAYOUT.HEADER.ACTIONS.LOGIN')}
                </FabButtonAnimateOutlined>
                <FabButtonAnimate
                  key="btn-register"
                  data-cy="btn-register"
                  variant="extended"
                  size={!isSmallDesktop ? 'medium' : 'small'}
                  // target="_blank"
                  // href={PATH_TALK_ADVISOR_MAIN}
                  href={'/sales'}
                  onClick={() => mapEvent(sellerEvent)}
                >
                  {t('COMMON.ACTIONS.REGISTER')}
                </FabButtonAnimate>
              </Box>
            )}

            {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
          </Container>
        </Toolbar>
        <SeparatorBox />
        {isOffset && <Shadow />}
      </MainBox>

      {SEASONAL_DEMAND_ENABLED && country === 'MX' && (
        <SeasonalContainer>{t('LAYOUT.HEADER.SEASONAL')}</SeasonalContainer>
      )}
      {SEASONAL_DEMAND_ENABLED_CL && (
        <SeasonalContainer>
          <span>
            <b>{t('LAYOUT.HEADER.SEASONAL_CL.HEADER.QUESTION')}</b>&nbsp;
            {t('LAYOUT.HEADER.SEASONAL_CL.HEADER.CS')} &nbsp;
            <a
              href={country === 'CL' ? PATH_TICKET_ZOHODESK_CL : PATH_TICKET_ZOHODESK_LATAM}
              target="_blank"
            >
              {t('LAYOUT.HEADER.SEASONAL_CL.MAIL')}
            </a>
          </span>
        </SeasonalContainer>
      )}
    </AppBar>
  );
};

const Shadow = ({ sx, ...other }: BoxProps) => {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z24,
        ...sx,
      }}
      {...other}
    />
  );
};

const SeasonalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),

  height: 48,
  fontSize: '1rem',
  fontWeight: 400,
  '& a': {
    color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
    height: 'auto',
    fontSize: '0.85rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SeasonalContainerInvisible = styled(SeasonalContainer)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: 'none',

  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),

  height: 48,
  fontSize: '0.85rem',
  fontWeight: 400,
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'flex',

    height: 'auto',
    fontSize: '0.85rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
    zIndex: 1000,
  },
}));

export default Header;
